<template>
  <div class="mains">
    <div class="mains_right">
      <div class="breadcrumbTop"><breadcrumbTop></breadcrumbTop></div>
      <div class="conent">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collapse: false,
    };
  },
  methods: {
    click(e) {
      console.log("船长", e);
      this.collapse = e;
    },
  },
};
</script>

<style lang="scss">
// @import url("./assets/css/base.css");
</style>
