import {
	get,
	post,
	put,
	del} from "./http";

// export default ({
// 	delData:p=>post("/api/kaijiang/delData",p),//清空数据
// 	selectIssue:p=>post("/api/kaijiang/selectIssue",p),//查询是否重复期数
// 	zhihe__jifen:p=>post("/api/kaijiang/zhihe__jifen",p),//质和比积分
// 	jiou__jifen:p=>post("/api/kaijiang/jiou__jifen",p),//奇偶比积分
// 	daxiao__jifen:p=>post("/api/kaijiang/daxiao__jifen",p),//大小比积分
// 	kuadu__jifen:p=>post("/api/kaijiang/kuadu__jifen",p),//跨度积分
// 	hezhi__jifen:p=>post("/api/kaijiang/hezhi__jifen",p),//和值积分
// 	ge__jifen:p=>post("/api/kaijiang/ge__jifen",p),//个积分
// 	shi__jifen:p=>post("/api/kaijiang/shi__jifen",p),//十积分
// 	bai__jifen:p=>post("/api/kaijiang/bai__jifen",p),//百积分
// 	update_fenshu:p=>post("/api/kaijiang/update_fenshu",p),//更新积分
// 	update_issue:p=>post("/api/kaijiang/update_issue",p),//更新期数
// 	total_nums:p=>post("/api/kaijiang/total_nums",p),//总期数
// 	yuceNum:p=>post("/api/kaijiang/yuceNum",p),//预测数据
// 	totalQi:p=>get("/api/kaijiang/totalQi",p),//总期数
// 	addBai_yuce:p=>post("/api/kaijiang/addBai_yuce",p),//录入预测数据
// 	getIssue_yuce:p=>get("/api/kaijiang/getIssue_yuce",p),//获取预测最新一期
// 	yuceNum_upDate:p=>post("/api/kaijiang/yuceNum_upDate",p),//更新预测
// 	userDetail_pay	:p=>post("/api/kaijiang/userDetail_pay",p),//用户指定购买

// 	selectUser:p=>post("/api/user/selectUser",p),//查询用户
// 	addUserTo:p=>post("/api/user/addUserTo",p),//生成用户
// 	addKaijiang:p=>post("/api/kaijiang/addKaijiang",p),//录入开奖号码
// 	select:p=>post("/api/kaijiang/select",p),//随机用户
// 	getIssue:p=>post("/api/kaijiang/getIssue",p),//获取最新期数
// 	addBai:p=>post("/api/kaijiang/addBai",p),//录入百位购买记录

// 	addBai_liandui:p=>post("/api/kaijiang/addBai_liandui",p),//录入百位连对记录
// 	getPh_bai:p=>post("/api/kaijiang/getPh_bai",p),//获取百位连对榜

// 	add_Shi_liandui:p=>post("/api/kaijiang/add_Shi_liandui",p),//录入十位连对记录
// 	getPh_shi:p=>post("/api/kaijiang/getPh_shi",p),//获取十位连对榜

// 	add_ge_liandui:p=>post("/api/kaijiang/add_ge_liandui",p),//录入个位连对记录
// 	getPh_ge:p=>post("/api/kaijiang/getPh_ge",p),//获取个位连对榜

// 	add_hezhi_liandui:p=>post("/api/kaijiang/add_hezhi_liandui",p),//录入和值连对记录
// 	getPh_hezhi:p=>post("/api/kaijiang/getPh_hezhi",p),//获取和值连对榜

// 	add_kuadu_liandui:p=>post("/api/kaijiang/add_kuadu_liandui",p),//录入跨度连对记录
// 	getPh_kuadu:p=>post("/api/kaijiang/getPh_kuadu",p),//获取跨度连对榜

// 	add_daxiao_liandui:p=>post("/api/kaijiang/add_daxiao_liandui",p),//录入大小比连对记录
// 	getPh_daxiao:p=>post("/api/kaijiang/getPh_daxiao",p),//获取大小比连对榜

// 	add_jiou_liandui:p=>post("/api/kaijiang/add_jiou_liandui",p),//录入奇偶比连对记录
// 	getPh_jiou:p=>post("/api/kaijiang/getPh_jiou",p),//获取奇偶比连对榜

// 	add_zhihe_liandui:p=>post("/api/kaijiang/add_zhihe_liandui",p),//录入质和比连对记录
// 	getPh_zhihe:p=>post("/api/kaijiang/getPh_zhihe",p),//获取质和比连对榜
// })
export default ({
	delData:p=>post("/kaijiang/delData",p),//清空数据
	selectIssue:p=>post("/kaijiang/selectIssue",p),//查询是否重复期数
	zhihe__jifen:p=>post("/kaijiang/zhihe__jifen",p),//质和比积分
	jiou__jifen:p=>post("/kaijiang/jiou__jifen",p),//奇偶比积分
	daxiao__jifen:p=>post("/kaijiang/daxiao__jifen",p),//大小比积分
	kuadu__jifen:p=>post("/kaijiang/kuadu__jifen",p),//跨度积分
	hezhi__jifen:p=>post("/kaijiang/hezhi__jifen",p),//和值积分
	ge__jifen:p=>post("/kaijiang/ge__jifen",p),//个积分
	shi__jifen:p=>post("/kaijiang/shi__jifen",p),//十积分
	bai__jifen:p=>post("/kaijiang/bai__jifen",p),//百积分
	update_fenshu:p=>post("/kaijiang/update_fenshu",p),//更新积分
	update_issue:p=>post("/kaijiang/update_issue",p),//更新期数
	total_nums: p => post("/kaijiang/total_nums", p),//总期数
	yuceNum: p => post("/kaijiang/yuceNum", p),//预测数据
	totalQi: p => get("/kaijiang/totalQi", p),//总期数
	addBai_yuce: p => post("/kaijiang/addBai_yuce", p),//录入预测数据
	getIssue_yuce: p => get("/kaijiang/getIssue_yuce", p),//获取预测最新一期
	yuceNum_upDate: p => post("/kaijiang/yuceNum_upDate", p),//更新预测
	userDetail_pay: p => post("/kaijiang/userDetail_pay", p),//用户指定购买
	selectUser: p => post("/user/selectUser", p),//查询用户
	addUserTo: p => post("/user/addUserTo", p),//生成用户
	addKaijiang: p => post("/kaijiang/addKaijiang", p),//录入开奖号码
	select: p => post("/kaijiang/select", p),//随机用户
	getIssue: p => post("/kaijiang/getIssue", p),//获取最新期数
	addBai: p => post("/kaijiang/addBai", p),//录入百位购买记录

	addBai_liandui: p => post("/kaijiang/addBai_liandui", p),//录入百位连对记录
	getPh_bai: p => post("/kaijiang/getPh_bai", p),//获取百位连对榜

	add_Shi_liandui: p => post("/kaijiang/add_Shi_liandui", p),//录入十位连对记录
	getPh_shi: p => post("/kaijiang/getPh_shi", p),//获取十位连对榜

	add_ge_liandui: p => post("/kaijiang/add_ge_liandui", p),//录入个位连对记录
	getPh_ge: p => post("/kaijiang/getPh_ge", p),//获取个位连对榜

	add_hezhi_liandui: p => post("/kaijiang/add_hezhi_liandui", p),//录入和值连对记录
	getPh_hezhi: p => post("/kaijiang/getPh_hezhi", p),//获取和值连对榜

	add_kuadu_liandui: p => post("/kaijiang/add_kuadu_liandui", p),//录入跨度连对记录
	getPh_kuadu: p => post("/kaijiang/getPh_kuadu", p),//获取跨度连对榜

	add_daxiao_liandui: p => post("/kaijiang/add_daxiao_liandui", p),//录入大小比连对记录
	getPh_daxiao: p => post("/kaijiang/getPh_daxiao", p),//获取大小比连对榜

	add_jiou_liandui: p => post("/kaijiang/add_jiou_liandui", p),//录入奇偶比连对记录
	getPh_jiou: p => post("/kaijiang/getPh_jiou", p),//获取奇偶比连对榜

	add_zhihe_liandui: p => post("/kaijiang/add_zhihe_liandui", p),//录入质和比连对记录
	getPh_zhihe: p => post("/kaijiang/getPh_zhihe", p),//获取质和比连对榜
})
