<template>
  <div class="predict">
    <div class="backConts" style="margin: 0 0 0px 0; height: 60px">
      <div style="">
        <div class="backContsDiv" @click="back">
          <el-icon><arrow-left /></el-icon>返回
        </div>
      </div>
      <el-select
        v-show="activeName == 0"
        v-model="selectValue"
        class="m-2"
        placeholder="选择期数"
        size="large"
        @change="yuceChange"
      >
        <el-option
          v-for="(item, index) in totalQiData"
          :key="index"
          :label="item.issue"
          :value="index"
        />
      </el-select>
    </div>
    <div>
      <!--  <el-tabs v-model="activeName" class="demo-tabs" @click="handleClick">
        <el-tab-pane label="当期预测" name="0"></el-tab-pane>
        <el-tab-pane label="预测排行" name="1"></el-tab-pane>
      </el-tabs> -->
      <div class="consa">
        <div
          :class="['sledcr', index == activeName ? 'selectDiv' : '']"
          v-for="(item, index) in lable"
          :key="index"
          @click="handleClick(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <el-table
      v-show="activeName == 0"
      :data="yuceData"
      height="calc(100vh - 210px)"
      style="width: 100%"
      border
    >
      <el-table-column prop="issue" label="期号" width="120" align="center" />
      <el-table-column
        prop="user_name"
        label="姓名"
        width="80"
        align="center"
      />
      <el-table-column
        prop="pay_num_bai_ding"
        label="百位定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_bai_sha"
        label="百位杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_shi_ding"
        label="十位定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_shi_sha"
        label="十位杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_ge_ding"
        label="个位定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_ge_sha"
        label="个位杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_hezhi_ding"
        label="和值定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_hezhi_sha"
        label="和值杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_kuadu_ding"
        label="跨度定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_kuadu_sha"
        label="跨度杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_daxiao_ding"
        label="大小比定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_daxiao_sha"
        label="大小比杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_jiou_ding"
        label="奇偶比定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_jiou_sha"
        label="奇偶比杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_zhihe_ding"
        label="质和比定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_zhihe_sha"
        label="质和比杀号预测"
        align="center"
      />
    </el-table>
    <div
      v-show="activeName == 1"
      class="consTable"
      style="display: flex; justify-content: space-between; flex-wrap: wrap"
    >
      <el-table
        :data="yuceData2"
        style="width: 49%; margin-bottom: 20px"
        border
      >
        <el-table-column
          prop="data.issue"
          label="百位"
          width="120"
          align="center"
        >
          <!-- <el-table-column
            prop="data.issue"
            label="期号"
            width="120"
            align="center"
          /> -->
          <el-table-column prop="title" label="类型" align="center">
          </el-table-column>
          <el-table-column prop="data.name" label="姓名" align="center" />

          <el-table-column
            prop="pay_num_bai_ding"
            label="预测号码"
            align="center"
          >
            <template #default="scope">
              <div>
                {{
                  scope.row.data.pay_num_bai_ding
                    ? scope.row.data.pay_num_bai_ding
                    : scope.row.data.pay_num_bai_sha
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="lz" label="连续" align="center">
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-table
        :data="yuceData3"
        style="width: 49%; margin-bottom: 20px"
        border
      >
        <el-table-column
          prop="data.issue"
          label="十位"
          width="120"
          align="center"
        >
          <!-- <el-table-column
            prop="data.issue"
            label="期号"
            width="120"
            align="center"
          /> -->
          <el-table-column prop="title" label="类型" align="center">
          </el-table-column>
          <el-table-column prop="data.name" label="姓名" align="center" />

          <el-table-column
            prop="pay_num_bai_ding"
            label="预测号码"
            align="center"
          >
            <template #default="scope">
              <div>
                {{
                  scope.row.data.pay_num_shi_ding
                    ? scope.row.data.pay_num_shi_ding
                    : scope.row.data.pay_num_shi_sha
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="lz" label="连续" align="center">
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-table
        :data="yuceData4"
        style="width: 49%; margin-bottom: 20px"
        border
      >
        <el-table-column
          prop="data.issue"
          label="个位"
          width="120"
          align="center"
        >
         <!--  <el-table-column
            prop="data.issue"
            label="期号"
            width="120"
            align="center"
          /> -->
          <el-table-column prop="title" label="类型" align="center">
          </el-table-column>

          <el-table-column prop="data.name" label="姓名" align="center" />
          <el-table-column
            prop="pay_num_bai_ding"
            label="预测号码"
            align="center"
          >
            <template #default="scope">
              <div>
                {{
                  scope.row.data.pay_num_ge_ding
                    ? scope.row.data.pay_num_ge_ding
                    : scope.row.data.pay_num_ge_sha
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="lz" label="连续" align="center">
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-table
        :data="yuceData5"
        style="width: 49%; margin-bottom: 20px"
        border
      >
        <el-table-column
          prop="data.issue"
          label="和值"
          width="120"
          align="center"
        >
          <!-- <el-table-column
            prop="data.issue"
            label="期号"
            width="120"
            align="center"
          /> -->
          <el-table-column prop="title" label="类型" align="center">
          </el-table-column>
          <el-table-column prop="data.name" label="姓名" align="center" />
          <el-table-column
            prop="pay_num_bai_ding"
            label="预测号码"
            align="center"
          >
            <template #default="scope">
              <div>
                {{
                  scope.row.data.pay_num_hezhi_ding
                    ? scope.row.data.pay_num_hezhi_ding
                    : scope.row.data.pay_num_hezhi_sha
                }}
              </div>
            </template>
          </el-table-column>
            <el-table-column prop="lz" label="连续" align="center">
        </el-table-column>
        </el-table-column>
      
      </el-table>
      <el-table
        :data="yuceData6"
        style="width: 49%; margin-bottom: 20px"
        border
      >
        <el-table-column
          prop="data.issue"
          label="跨度"
          width="120"
          align="center"
        >
          <!-- <el-table-column
            prop="data.issue"
            label="期号"
            width="120"
            align="center"
          /> -->
          <el-table-column prop="title" label="类型" align="center">
          </el-table-column>
          <el-table-column prop="data.name" label="姓名" align="center" />
          <el-table-column
            prop="pay_num_bai_ding"
            label="预测号码"
            align="center"
          >
            <template #default="scope">
              <div>
                {{
                  scope.row.data.pay_num_kuadu_ding
                    ? scope.row.data.pay_num_kuadu_ding
                    : scope.row.data.pay_num_kuadu_sha
                }}
              </div>
            </template>
          </el-table-column>
           <el-table-column prop="lz" label="连续" align="center">
        </el-table-column>
        </el-table-column>
       
      </el-table>
      <el-table
        :data="yuceData7"
        style="width: 49%; margin-bottom: 20px"
        border
      >
        <el-table-column
          prop="data.issue"
          label="大小比"
          width="120"
          align="center"
        >
          <!-- <el-table-column
            prop="data.issue"
            label="期号"
            width="120"
            align="center"
          /> -->
          <el-table-column prop="title" label="类型" align="center">
          </el-table-column>
          <el-table-column prop="data.name" label="姓名" align="center" />
          <el-table-column
            prop="pay_num_bai_ding"
            label="预测号码"
            align="center"
          >
            <template #default="scope">
              <div>
                {{
                  scope.row.data.pay_num_daxiao_ding
                    ? scope.row.data.pay_num_daxiao_ding
                    : scope.row.data.pay_num_daxiao_sha
                }}
              </div>
            </template>
          </el-table-column>
             <el-table-column prop="lz" label="连续" align="center">
        </el-table-column>
        </el-table-column>
     
      </el-table>
      <el-table
        :data="yuceData8"
        style="width: 49%; margin-bottom: 20px"
        border
      >
        <el-table-column
          prop="data.issue"
          label="奇偶比"
          width="120"
          align="center"
        >
          <!-- <el-table-column
            prop="data.issue"
            label="期号"
            width="120"
            align="center"
          /> -->
          <el-table-column prop="title" label="类型" align="center">
          </el-table-column>
          <el-table-column prop="data.name" label="姓名" align="center" />
          <el-table-column
            prop="pay_num_bai_ding"
            label="预测号码"
            align="center"
          >
            <template #default="scope">
              <div>
                {{
                  scope.row.data.pay_num_jiou_ding
                    ? scope.row.data.pay_num_jiou_ding
                    : scope.row.data.pay_num_jiou_sha
                }}
              </div>
            </template>
          </el-table-column>
             <el-table-column prop="lz" label="连续" align="center">
        </el-table-column>
        </el-table-column>
      </el-table>
      <el-table
        :data="yuceData9"
        style="width: 49%; margin-bottom: 20px"
        border
      >
        <el-table-column
          prop="data.issue"
          label="质和比"
          width="120"
          align="center"
        >
          <!-- <el-table-column
            prop="data.issue"
            label="期号"
            width="120"
            align="center"
          /> -->
          <el-table-column prop="title" label="类型" align="center">
          </el-table-column>

          <el-table-column prop="data.name" label="姓名" align="center" />
          <el-table-column
            prop="pay_num_bai_ding"
            label="预测号码"
            align="center"
          >
            <template #default="scope">
              <div>
                {{
                  scope.row.data.pay_num_zhihe_ding
                    ? scope.row.data.pay_num_zhihe_ding
                    : scope.row.data.pay_num_zhihe_sha
                }}
              </div>
            </template>
          </el-table-column>
             <el-table-column prop="lz" label="连续" align="center">
        </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import api from "./../../zRequest/api.js";
import { ElMessage } from "element-plus";
export default {
  props: { yuceData: "", totalQiData: "" },
  data() {
    return {
      activeName: "0",
      tableData: [],
      selectValue: "",
      yuceData2: [],
      yuceData3: [],
      yuceData4: [],
      yuceData5: [],
      yuceData6: [],
      yuceData7: [],
      yuceData8: [],
      yuceData9: [],
      lable: [
        {
          name: "当期预测",
        },
        {
          name: "预测排行",
        },
      ],
    };
  },
  mounted() {
    console.log(this.totalQiData);
  },
  methods: {
    handleClick(index) {
      console.log(this.activeName);
      this.activeName = index;
      if (this.activeName == 1) {
        this.bai__jifen();
      }
    },
    bai__jifen() {
      api.bai__jifen().then((res) => {
        console.log(res);
        this.yuceData2 = res.data;
      });
      api.shi__jifen().then((ress) => {
        this.yuceData3 = ress.data;
      });
      api.ge__jifen().then((ress) => {
        this.yuceData4 = ress.data;
      });
      api.hezhi__jifen().then((ress) => {
        this.yuceData5 = ress.data;
      });
      api.kuadu__jifen().then((ress) => {
        this.yuceData6 = ress.data;
      });
      api.daxiao__jifen().then((ress) => {
        this.yuceData7 = ress.data;
      });
      api.jiou__jifen().then((ress) => {
        this.yuceData8 = ress.data;
      });
      api.zhihe__jifen().then((ress) => {
        this.yuceData9 = ress.data;
      });
    },
    back() {
      this.$emit("back", false);
    },
    yuceChange() {
      console.log(this.selectValue);
      this.$emit("yuceChange", this.totalQiData[this.selectValue].issue);
      //   api.yuceNum({ issue: this.totalQiData[this.selectValue].issue}).then((res) => {
      //     console.log( res.data);
      //     this.yuceData = res.data;
      //   });
    },
  },
};
</script>

<style lang="scss">
.predict {
  padding: 20px;
  .backConts {
    margin-bottom: 20px;
    display: flex;

    font-size: 18px;
    cursor: pointer;
    justify-content: space-between;
    .backContsDiv {
      display: flex;
      align-items: center;
    }
  }
  .el-table thead th {
    background: #e7e7e7 !important;
  }
  .consa {
    display: flex;
    div {
      color: #303133;
      padding: 10px 0;
      width: 100px;
      cursor: pointer;
    }
    .selectDiv {
      color: #409eff;
    }
  }
}
</style>
