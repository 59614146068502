import axios from "axios";
import QS from "qs"; //引入qs模块,用来序列化post类型的数据
import Vue from "vue";
import router from '@/router'
//环境的切换
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "";
} else if (process.env.NODE_ENV == "debug") {
  axios.defaults.baseURL = "http://caipiaoapi.029xa.cn/";
} else if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = "http://caipiaoapi.029xa.cn/";
}
//设置请求超时
axios.defaults.timeout = 30000;
// let  type= app-type
//post请求头的设置
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.headers.get["Content-Type"] =
  "application/json;charset=UTF-8;";
// 请求拦截器
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem("token")) {
      config.headers['X-Pat-Manager-Token'] = localStorage.getItem("token");
    }

    return config;
  },
  error => {
    console.log(error)
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    let that = this
    console.log('response', response.status)
    if (response.status === 200) {

      if (response.data.errno === 666) {
        Vue.prototype.$message({
          type: "error",
          message: "身份已过期，请重新登录"
        });
        localStorage.removeItem("token");
        router.push({ path: '/login' })
        return false;
      } else if (response.data.errno === 506) {
        Vue.prototype.$message({
          type: "error",
          message: response.data.errmsg
        });
        return false;
      }
      return Promise.resolve(response.data);
    } else {
      console.log('response', 7897987)
      return Promise.reject(response);
    }
  }, function (error) {
    console.log(error)
    // if (400 === error.response.status) {
    //   router.push({ path: '/login' })
    // } else {
    //   return Promise.reject(error);
    // }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    // QS.stringify(params)
    axios
      .post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

 export function put(url, params) {
  return new Promise((resolve, reject) => {
    // QS.stringify(params)
    axios
      .put(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function del(url, params) {
  return new Promise((resolve, reject) => {
    // QS.stringify(params)
    axios
      .delete(url, {
        params
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}