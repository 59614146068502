<template>
	<div class="pHang">
		<div class="ul">
			<div class="li" v-for="(item, index) in tabelData" :key="index" :style="(index + 1) % 3 == 0 ? 'margin-right:0px;' : ''">
				<div class="header">
					<div>3D胆码组--{{ item.title }}</div>
					<div>
						{{ total }}期
						<!-- <span>更多</span> -->
					</div>
				</div>
				<div>
					<div class="conts" v-for="(im, inx) in item.data" :key="inx">
						<div style="font-size:20px;">{{ inx + 1 }}</div>
						<div>{{ im.name }}</div>
						<div style="color:#FF2525;" v-if="indexTo==0">
							{{ total }}中{{ index == 0 ? im.liandui_bai_ding : index == 1 ? im.liancuo_bai_ding : index == 2 ? im.liandui_bai_sha : im.liancuo_bai_sha }}
						</div>
						<div style="color:#FF2525;" v-if="indexTo==1">
							{{ total }}中{{ index == 0 ? im.liandui_shi_ding : index == 1 ? im.liancuo_shi_ding : index == 2 ? im.liandui_shi_sha : im.liancuo_shi_sha }}
						</div>
						<div style="color:#FF2525;" v-if="indexTo==2">
							{{ total }}中{{ index == 0 ? im.liandui_ge_ding : index == 1 ? im.liancuo_ge_ding : index == 2 ? im.liandui_ge_sha : im.liancuo_ge_sha }}
						</div>
						<div style="color:#FF2525;" v-if="indexTo==3">
							{{ total }}中{{ index == 0 ? im.liandui_hezhi_ding : index == 1 ? im.liancuo_hezhi_ding : index == 2 ? im.liandui_hezhi_sha : im.liancuo_hezhi_sha }}
						</div>
						<div style="color:#FF2525;" v-if="indexTo==4">
							{{ total }}中{{ index == 0 ? im.liandui_kuadu_ding : index == 1 ? im.liancuo_kuadu_ding : index == 2 ? im.liandui_kuadu_sha : im.liancuo_kuadu_sha }}
						</div>
						<div style="color:#FF2525;" v-if="indexTo==5">
							{{ total }}中{{ index == 0 ? im.liandui_daxiao_ding : index == 1 ? im.liancuo_daxiao_ding : index == 2 ? im.liandui_daxiao_sha : im.liancuo_daxiao_sha }}
						</div>
						<div style="color:#FF2525;" v-if="indexTo==6">
							{{ total }}中{{ index == 0 ? im.liandui_jiou_ding : index == 1 ? im.liancuo_jiou_ding : index == 2 ? im.liandui_jiou_sha : im.liancuo_jiou_sha }}
						</div>
						<div style="color:#FF2525;" v-if="indexTo==7">
							{{ total }}中{{ index == 0 ? im.liandui_zhihe_ding : index == 1 ? im.liancuo_zhihe_ding : index == 2 ? im.liandui_zhihe_sha : im.liancuo_zhihe_sha }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from './../../zRequest/api.js';
import { ElMessage } from 'element-plus';
import { defineComponent, ref } from 'vue';
export default {
	name: 'pHang',
	data() {
		return {
			
		};
	},
	props: {

		tabelData:'',
		total:'',
		indexTo:'',
	},
	mounted() {
		console.log(this.indexTo)
	},
	methods: {
		    //    returnNumber1 = this.bubbleSort(returnNumber1);
            // returnNumber2 = this.bubbleSortt(returnNumber2);
            // returnNumber3 = this.bubbleSort(returnNumber3);
            // returnNumber4 = this.bubbleSort(returnNumber4);
            // returnNumber5 = this.bubbleSort(returnNumber5);
            // returnNumber6 = this.bubbleSort(returnNumber6);
            // returnNumber7 = this.bubbleSort(returnNumber7);
            // returnNumber8 = this.bubbleSort(returnNumber8);
	}
};
</script>

<style lang="scss">
.pHang {
	padding: 20px;
	.ul {
		display: flex;
		flex-wrap: wrap;
	}
	.li {
		width: 424px;
		margin-right: 40px;
		margin-bottom: 30px;
		border: 1px solid #bbbbbb;
		.conts {
			display: flex;
			height: 40px;
			align-items: center;
			justify-content: space-between;
			div {
				width: 30%;
				text-align: center;
				font-size: 18px;
				color: #101010;
			}
		}
	}
	.header {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		color: #101010;
		padding: 0 20px !important;
		height: 64px;
		align-items: center;
		span {
			font-size: 14px;
			margin-left: 10px;
		}
	}
}
</style>
