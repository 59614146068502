<template>
	<div class="liansha">
		<div class="headers">
			<div class="title">
				百位{{indexTo==0?'连对':'连错'}}榜--
				<span>{{tabelData.title}}</span>
			</div>
			<div class="qiNum">{{total}}期</div>
		</div>
		<div class="lable">
			<div class="td">
				<div>期号</div>
				<div class="select">{{indexTo==0?'百位':indexTo==1?'十位':indexTo==2?'个位':indexTo==3?'和值':indexTo==4?'跨度':indexTo==5?'大小比':indexTo==6?'奇偶比':'质和比'}}开奖号码</div>
				<div>排名</div>
				<div class="select">专家</div>
				<div>下期预测{{tabelData.title}}</div>
				<div class="select">成绩</div>
				<div>当前连对</div>
				<div class="select">最大连对</div>
			</div>
			<div v-if="indexTo==0" class="td" v-for="(item, index) in tabelData.data" :key="index">
				<div>{{ getIssueData.issue }}</div>
				<div class="select">{{ getIssueData.bai_num }}</div>
				<div>{{ index+1 }}</div>
				<div class="select selectCur " @click="userDetail(item.id)">{{ item.name }}</div>
				<div>{{ item.pay_num_bai_ding?item.pay_num_bai_ding:item.pay_num_bai_sha}}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==0 && indexThree==0">连中{{ item.liandui_bai_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==0 && indexThree==1">连中{{ item.liandui_bai_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==0 && indexThree==0">连中{{ item.liancuo_bai_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==0 && indexThree==1">连中{{ item.liancuo_bai_sha }}</div>

				<div  v-if="selectIndex==0 && indexTo==0 && indexThree==0">{{ item.liandui_bai_ding }}</div>
				<div  v-if="selectIndex==0 && indexTo==0 && indexThree==1">{{ item.liandui_bai_sha }}</div>
				<div v-if="selectIndex==1 && indexTo==0 && indexThree==0">{{ item.liancuo_bai_ding }}</div>
				<div  v-if="selectIndex==1 && indexTo==0 && indexThree==1">{{ item.liancuo_bai_sha }}</div>

				<div class="select" v-if="selectIndex==0 && indexTo==0 && indexThree==0">{{ item.liandui_bai_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==0 && indexThree==1">{{ item.liandui_bai_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==0 && indexThree==0">{{ item.liancuo_bai_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==0 && indexThree==1">{{ item.liancuo_bai_sha }}</div>

				<!-- <div>{{ item.liandui_bai_ding }}</div>
				<div class="select">{{ item.liandui_bai_ding }}</div> -->
			</div>
			<div v-if="indexTo==1" class="td" v-for="(item, index) in tabelData.data" :key="index">
				<div>{{ getIssueData.issue }}</div>
				<div class="select">{{ getIssueData.shi_num }}</div>
				<div>{{ index+1 }}</div>
				<div class="select selectCur"  @click="userDetail(item.id)">{{ item.name }}</div>
				<div>{{ item.pay_num_shi_ding?item.pay_num_shi_ding:item.pay_num_shi_sha}}</div>
				<!-- <div class="select">连中{{ item.liandui_shi_ding }}</div> -->
				<!-- <div>{{ item.liandui_shi_ding }}</div>
				<div class="select">{{ item.liandui_shi_ding }}</div> -->
				<div class="select" v-if="selectIndex==0 && indexTo==1 && indexThree==0">连中{{ item.liandui_shi_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==1 && indexThree==1">连中{{ item.liandui_shi_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==1 && indexThree==0">连中{{ item.liancuo_shi_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==1 && indexThree==1">连中{{ item.liancuo_shi_sha }}</div>

				<div  v-if="selectIndex==0 && indexTo==1 && indexThree==0">{{ item.liandui_shi_ding }}</div>
				<div  v-if="selectIndex==0 && indexTo==1 && indexThree==1">{{ item.liandui_shi_sha }}</div>
				<div v-if="selectIndex==1 && indexTo==1 && indexThree==0">{{ item.liancuo_shi_ding }}</div>
				<div  v-if="selectIndex==1 && indexTo==1 && indexThree==1">{{ item.liancuo_shi_sha }}</div>

				<div class="select" v-if="selectIndex==0 && indexTo==1 && indexThree==0">{{ item.liandui_shi_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==1 && indexThree==1">{{ item.liandui_shi_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==1 && indexThree==0">{{ item.liancuo_shi_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==1 && indexThree==1">{{ item.liancuo_shi_sha }}</div>


			</div>
			<div v-if="indexTo==2" class="td" v-for="(item, index) in tabelData.data" :key="index">
				<div>{{ getIssueData.issue }}</div>
				<div class="select">{{ getIssueData.ge_num }}</div>
				<div>{{ index+1 }}</div>
				<div class="select  selectCur" @click="userDetail(item.id)">{{ item.name }}</div>
				<div>{{ item.pay_num_ge_ding?item.pay_num_ge_ding:item.pay_num_ge_sha}}</div>
				<!-- <div class="select">连中{{ item.liandui_ge_ding }}</div>
				<div>{{ item.liandui_ge_ding }}</div>
				<div class="select">{{ item.liandui_ge_ding }}</div> -->
				<div class="select" v-if="selectIndex==0 && indexTo==2 && indexThree==0">连中{{ item.liandui_ge_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==2 && indexThree==1">连中{{ item.liandui_ge_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==2 && indexThree==0">连中{{ item.liancuo_ge_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==2 && indexThree==1">连中{{ item.liancuo_ge_sha }}</div>

				<div  v-if="selectIndex==0 && indexTo==2 && indexThree==0">{{ item.liandui_ge_ding }}</div>
				<div  v-if="selectIndex==0 && indexTo==2 && indexThree==1">{{ item.liandui_ge_sha }}</div>
				<div v-if="selectIndex==1 && indexTo==2 && indexThree==0">{{ item.liancuo_ge_ding }}</div>
				<div  v-if="selectIndex==1 && indexTo==2 && indexThree==1">{{ item.liancuo_ge_sha }}</div>

				<div class="select" v-if="selectIndex==0 && indexTo==2 && indexThree==0">{{ item.liandui_ge_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==2 && indexThree==1">{{ item.liandui_ge_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==2 && indexThree==0">{{ item.liancuo_ge_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==2 && indexThree==1">{{ item.liancuo_ge_sha }}</div>


			</div>
			<div v-if="indexTo==3" class="td" v-for="(item, index) in tabelData.data" :key="index">
				<div>{{ getIssueData.issue }}</div>
				<div class="select">{{ getIssueData.he_num }}</div>
				<div>{{ index+1 }}</div>
				<div class="select selectCur" @click="userDetail(item.id)">{{ item.name }}</div>
					<div>{{ item.pay_num_hezhi_ding?item.pay_num_hezhi_ding:item.pay_num_hezhi_sha}}</div>
				<!-- <div class="select">连中{{ item.liandui_hezhi_ding }}</div>
				<div>{{ item.liandui_hezhi_ding }}</div>
				<div class="select">{{ item.liandui_hezhi_ding }}</div> -->
				<div class="select" v-if="selectIndex==0 && indexTo==3 && indexThree==0">连中{{ item.liandui_hezhi_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==3 && indexThree==1">连中{{ item.liandui_hezhi_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==3 && indexThree==0">连中{{ item.liancuo_hezhi_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==3 && indexThree==1">连中{{ item.liancuo_hezhi_sha }}</div>

				<div  v-if="selectIndex==0 && indexTo==3 && indexThree==0">{{ item.liandui_hezhi_ding }}</div>
				<div  v-if="selectIndex==0 && indexTo==3 && indexThree==1">{{ item.liandui_hezhi_sha }}</div>
				<div v-if="selectIndex==1 && indexTo==3 && indexThree==0">{{ item.liancuo_hezhi_ding }}</div>
				<div  v-if="selectIndex==1 && indexTo==3 && indexThree==1">{{ item.liancuo_hezhi_sha }}</div>

				<div class="select" v-if="selectIndex==0 && indexTo==3 && indexThree==0">{{ item.liandui_hezhi_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==3 && indexThree==1">{{ item.liandui_hezhi_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==3 && indexThree==0">{{ item.liancuo_hezhi_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==3 && indexThree==1">{{ item.liancuo_hezhi_sha }}</div>
			</div>
			<div v-if="indexTo==4" class="td" v-for="(item, index) in tabelData.data" :key="index">
				<div>{{ getIssueData.issue }}</div>
				<div class="select">{{ getIssueData.kua_num }}</div>
				<div>{{ index+1 }}</div>
				<div class="select selectCur" @click="userDetail(item.id)">{{ item.name }}</div>
					<div>{{ item.pay_num_kuadu_ding?item.pay_num_kuadu_ding:item.pay_num_kuadu_sha}}</div>
				<!-- <div class="select">连中{{ item.liandui_kuadu_ding }}</div>
				<div>{{ item.liandui_kuadu_ding }}</div>
				<div class="select">{{ item.liandui_kuadu_ding }}</div> -->
				<div class="select" v-if="selectIndex==0 && indexTo==4 && indexThree==0">连中{{ item.liandui_kuadu_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==4 && indexThree==1">连中{{ item.liandui_kuadu_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==4 && indexThree==0">连中{{ item.liancuo_kuadu_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==4 && indexThree==1">连中{{ item.liancuo_kuadu_sha }}</div>

				<div  v-if="selectIndex==0 && indexTo==4 && indexThree==0">{{ item.liandui_kuadu_ding }}</div>
				<div  v-if="selectIndex==0 && indexTo==4 && indexThree==1">{{ item.liandui_kuadu_sha }}</div>
				<div v-if="selectIndex==1 && indexTo==4 && indexThree==0">{{ item.liancuo_kuadu_ding }}</div>
				<div  v-if="selectIndex==1 && indexTo==4 && indexThree==1">{{ item.liancuo_kuadu_sha }}</div>

				<div class="select" v-if="selectIndex==0 && indexTo==4 && indexThree==0">{{ item.liandui_kuadu_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==4 && indexThree==1">{{ item.liandui_kuadu_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==4 && indexThree==0">{{ item.liancuo_kuadu_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==4 && indexThree==1">{{ item.liancuo_kuadu_sha }}</div>
			</div>
			<div v-if="indexTo==5" class="td" v-for="(item, index) in tabelData.data" :key="index">
				<div>{{ getIssueData.issue }}</div>
				<div class="select">{{ getIssueData.daxiao_num }}</div>
				<div>{{ index+1 }}</div>
				<div class="select selectCur" @click="userDetail(item.id)">{{ item.name }}</div>
					<div>{{ item.pay_num_daxiao_ding?item.pay_num_daxiao_ding:item.pay_num_daxiao_sha}}</div>
				<!-- <div class="select">连中{{ item.liandui_daxiao_ding }}</div>
				<div>{{ item.liandui_daxiao_ding }}</div>
				<div class="select">{{ item.liandui_daxiao_ding }}</div> -->
				<div class="select" v-if="selectIndex==0 && indexTo==5 && indexThree==0">连中{{ item.liandui_daxiao_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==5 && indexThree==1">连中{{ item.liandui_daxiao_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==5 && indexThree==0">连中{{ item.liancuo_daxiao_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==5 && indexThree==1">连中{{ item.liancuo_daxiao_sha }}</div>

				<div  v-if="selectIndex==0 && indexTo==5 && indexThree==0">{{ item.liandui_daxiao_ding }}</div>
				<div  v-if="selectIndex==0 && indexTo==5 && indexThree==1">{{ item.liandui_daxiao_sha }}</div>
				<div v-if="selectIndex==1 && indexTo==5 && indexThree==0">{{ item.liancuo_daxiao_ding }}</div>
				<div  v-if="selectIndex==1 && indexTo==5 && indexThree==1">{{ item.liancuo_daxiao_sha }}</div>

				<div class="select" v-if="selectIndex==0 && indexTo==5 && indexThree==0">{{ item.liandui_daxiao_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==5 && indexThree==1">{{ item.liandui_daxiao_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==5 && indexThree==0">{{ item.liancuo_daxiao_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==5 && indexThree==1">{{ item.liancuo_daxiao_sha }}</div>
			</div>
			<div v-if="indexTo==6" class="td" v-for="(item, index) in tabelData.data" :key="index">
				<div>{{ getIssueData.issue }}</div>
				<div class="select">{{ getIssueData.jiou_num }}</div>
				<div>{{ index+1 }}</div>
				<div class="select selectCur" @click="userDetail(item.id)">{{ item.name }}</div>
						<div>{{ item.pay_num_jiou_ding?item.pay_num_jiou_ding:item.pay_num_jiou_sha}}</div>
				<!-- <div class="select">连中{{ item.liandui_jiou_ding }}</div>
				<div>{{ item.liandui_jiou_ding }}</div>
				<div class="select">{{ item.liandui_jiou_ding }}</div> -->
				<div class="select" v-if="selectIndex==0 && indexTo==6 && indexThree==0">连中{{ item.liandui_jiou_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==6 && indexThree==1">连中{{ item.liandui_jiou_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==6 && indexThree==0">连中{{ item.liancuo_jiou_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==6 && indexThree==1">连中{{ item.liancuo_jiou_sha }}</div>

				<div  v-if="selectIndex==0 && indexTo==6 && indexThree==0">{{ item.liandui_jiou_ding }}</div>
				<div  v-if="selectIndex==0 && indexTo==6 && indexThree==1">{{ item.liandui_jiou_sha }}</div>
				<div v-if="selectIndex==1  && indexTo==6 && indexThree==0">{{ item.liancuo_jiou_ding }}</div>
				<div  v-if="selectIndex==1 && indexTo==6 && indexThree==1">{{ item.liancuo_jiou_sha }}</div>

				<div class="select" v-if="selectIndex==0 && indexTo==6 && indexThree==0">{{ item.liandui_jiou_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==6 && indexThree==1">{{ item.liandui_jiou_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==6 && indexThree==0">{{ item.liancuo_jiou_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==6 && indexThree==1">{{ item.liancuo_jiou_sha }}</div>
			</div>
			<div v-if="indexTo==7" class="td" v-for="(item, index) in tabelData.data" :key="index">
				<div>{{ getIssueData.issue }}</div>
				<div class="select">{{ getIssueData.zhihe_num }}</div>
				<div>{{ index+1 }}</div>
				<div class="select selectCur" @click="userDetail(item.id)">{{ item.name }}</div>
						<div>{{ item.pay_num_zhihe_ding?item.pay_num_zhihe_ding:item.pay_num_zhihe_sha}}</div>
				<!-- <div class="select">连中{{ item.liandui_zhihe_ding }}</div>
				<div>{{ item.liandui_zhihe_ding }}</div>
				<div class="select">{{ item.liandui_zhihe_ding }}</div> -->
				<div class="select" v-if="selectIndex==0 && indexTo==7 && indexThree==0">连中{{ item.liandui_zhihe_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==7 && indexThree==1">连中{{ item.liandui_zhihe_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==7 && indexThree==0">连中{{ item.liancuo_zhihe_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==7 && indexThree==1">连中{{ item.liancuo_zhihe_sha }}</div>

				<div  v-if="selectIndex==0 && indexTo==7 && indexThree==0">{{ item.liandui_zhihe_ding }}</div>
				<div  v-if="selectIndex==0 && indexTo==7 && indexThree==1">{{ item.liandui_zhihe_sha }}</div>
				<div v-if="selectIndex==1  && indexTo==7 && indexThree==0">{{ item.liancuo_zhihe_ding }}</div>
				<div  v-if="selectIndex==1 && indexTo==7 && indexThree==1">{{ item.liancuo_zhihe_sha }}</div>

				<div class="select" v-if="selectIndex==0 && indexTo==7 && indexThree==0">{{ item.liandui_zhihe_ding }}</div>
				<div class="select" v-if="selectIndex==0 && indexTo==7 && indexThree==1">{{ item.liandui_zhihe_sha }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==7 && indexThree==0">{{ item.liancuo_zhihe_ding }}</div>
				<div class="select" v-if="selectIndex==1 && indexTo==7 && indexThree==1">{{ item.liancuo_zhihe_sha }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: { tabelData: '', total: '',getIssueData:'',selectIndex:'' ,indexTo:'',indexThree:''},
	data() {
		return {
			tabData: [
				{
					qishu: '2020070',
					kj_num: '2',
					pai: '1',
					zj_name: '李易峰',
					sima: '2，5，8，1',
					chengji: '连中6',
					liandui: '6期',
					zuida_liandui: '6期'
				},
				{
					qishu: '2020069',
					kj_num: '2',
					pai: '2',
					zj_name: '李易峰',
					sima: '2，5，8，1',
					chengji: '连中6',
					liandui: '6期',
					zuida_liandui: '6期'
				}
			]
		};
	},
	methods:{
		userDetail(id){
			console.log(id)
			this.$emit('userDetail',id)
		}
	}
	
	
};
</script>

<style lang="scss">
.liansha {
	.headers {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 68px 15px 20px;
		background: #e1e3e6;
		margin-top: 20px;
		.title {
			color: #101010;
			font-size: 14px;
			span {
				color: red;
			}
		}
		.qiNum {
			font-size: 12px;
			padding: 2px 0;
			width: 80px;
			border-radius: 5px;
			background: #ffffff;
			border: 1px solid #bbbbbb;
			text-align: center;
		}
	}
	.lable {
		.td {
			display: flex;
			div {
				font-size: 14px;
				color: #101010;
				flex: 1;
				text-align: center;
				height: 56px;
				line-height: 56px;
				border: 1px solid #bbbbbb;
			}
		}
		.select {
			background: #ffe9e9;
		}
		.selectCur{
			cursor: pointer;
		}
	}
}
</style>
