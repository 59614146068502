import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import IndexView from '../views/index.vue'
const routes = [

  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  // {
  //   path: '/HomeView',
  //   name: 'HomeView',
  //   component: HomeView
  // }
  
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach(async (to, from) => {
//   // canUserAccess() 返回 `true` 或 `false`
//   console.log(to, from)
//   return await canUserAccess(to)
// })
export default router
