// import { createApp } from 'vue'
// import App from './App.vue'
// import store from './store'
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// app.use(ElementPlus)
// createApp(App).use(store).mount('#app')
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import axios from "axios";
import * as ElIconModules from '@element-plus/icons-vue'
import vue from 'vue'
// import vueConfig from 'vue.config'
// var httpSever=require("http-server");
// import httpSever from "http-server"
// console.log('httpSever',httpSever)
const app = createApp(App).use(router)

for (const iconName in ElIconModules) {
    if (Reflect.has(ElIconModules, iconName)) {
        const item = ElIconModules[iconName]
        app.component(iconName, item)
    }
}

// axios
app.use(ElementPlus)
app.mount('#app')