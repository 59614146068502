<template>
  <div class="predict">
    <div @click="back" class="backConts" style="margin: 0 0 20px 0">
      <div class="backContsDiv">
        <el-icon><arrow-left /></el-icon>返回
      </div>
      <!-- <el-select
        v-model="selectValue"
        class="m-2"
        placeholder="选择期数"
        size="large"
        @change="yuceChange"
      >
        <el-option
          v-for="(item, index) in detailData"
          :key="index"
          :label="item.issue"
          :value="index"
        />
      </el-select> -->
    </div>
    <el-table
      :data="detailData"
      height="calc(100vh - 210px)"
      style="width: 100%"
      border
    >
      <el-table-column prop="issue" label="期号" width="120" align="center" />
      <el-table-column
        prop="user_name"
        label="姓名"
        width="80"
        align="center"
      />
      <el-table-column
        prop="pay_num_bai_ding"
        label="百位定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_bai_sha"
        label="百位杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_shi_ding"
        label="十位定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_shi_sha"
        label="十位杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_ge_ding"
        label="个位定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_ge_sha"
        label="个位杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_hezhi_ding"
        label="和值定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_hezhi_sha"
        label="和值杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_daxiao_ding"
        label="大小比定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_daxiao_sha"
        label="大小比杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_jiou_ding"
        label="奇偶比定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_jiou_sha"
        label="奇偶比杀号预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_zhihe_ding"
        label="质和比定胆预测"
        align="center"
      />
      <el-table-column
        prop="pay_num_zhihe_sha"
        label="质和比杀号预测"
        align="center"
      />
    </el-table>
  </div>
</template>

<script>
import { ref } from "vue";
import api from "./../../zRequest/api.js";
import { ElMessage } from "element-plus";
export default {
  props: { detailData: ""},
  data() {
    return {
      tableData: [],
      selectValue: "",
    };
  },
  mounted() {
   
  },
  methods: {
    back() {
      this.$emit("backDetail", false);
    },
    yuceChange() {
      console.log(this.selectValue);
      // this.$emit("yuceChange", this.totalQiData[this.selectValue].issue);
    },
  },
};
</script>

<style lang="scss">
.predict {
  padding: 20px;
  .backConts {
    margin-bottom: 20px;
    display: flex;

    font-size: 18px;
    cursor: pointer;
    justify-content: space-between;
    .backContsDiv {
      display: flex;
      align-items: center;
    }
  }
}
</style>
