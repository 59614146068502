<template>
  <div class="index">
    <!-- <el-button plain @click="generate">生成用户100</el-button>
    <el-button plain @click="select">生成定杀值</el-button>
    <el-button plain @click="addBai">购买百位</el-button>
    <el-button plain @click="addBai_liandui">百位连对</el-button>
    <el-button plain @click="delData">清空数据</el-button> -->
    <div class="mains">
      <div class="header">
        <div class="name">单期排行</div>
        <div class="title">3D第{{ getIssueData.issue }}期开奖</div>
        <div class="num">
          <div>开奖号:</div>
          <el-button plain @click="entryNumber">开奖号码录入</el-button>
        </div>
        <div class="rightCont">
          <div class="numCont">
            <div>
              百位开奖号:
              <br />
              {{ getIssueData.bai_num }}
            </div>
            <div>
              十位开奖号:
              <br />
              {{ getIssueData.shi_num }}
            </div>
            <div>
              个位开奖号:
              <br />
              {{ getIssueData.ge_num }}
            </div>
            <div>
              和值开奖号:
              <br />
              {{ getIssueData.he_num }}
            </div>
            <div>
              跨度开奖号:
              <br />
              {{ getIssueData.kua_num }}
            </div>
            <div>
              大小开奖号:
              <br />
              {{ getIssueData.daxiao_num }}
            </div>
            <div>
              奇偶比开奖号:
              <br />
              {{ getIssueData.jiou_num }}
            </div>
            <div>
              质合比开奖号:
              <br />
              {{ getIssueData.zhihe_num }}
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <div class="pai" @click="yuce"><div>预测</div></div>
            <div
              class="pai"
              style="
                margin-left: 10px;
                height: 20px;
                line-height: 20px;
                font-size: 10px;
                display: inline-flex;
                width: 50px;
                color: #333333;
              "
              @click="delData"
            >
              <div>清空数据</div>
            </div>
            <!-- <el-button plain @click="delData">清空数据</el-button> -->
          </div>
        </div>
      </div>
      <yuCe
        @back="back"
        :totalQiData="totalQiData"
        :yuceData="yuceData"
        @yuceChange="yuceChange"
        v-show="yucShow"
      ></yuCe>
      <userDetail
        v-show="detailShow"
        :detailData="detailData"
        @backDetail="backDetail"
      ></userDetail>
      <div v-if="!yucShow && !detailShow" class="cont">
        <div class="congtMain">
          <div class="type">排行</div>
          <div class="mainRight">
            <div class="head">
              <div
                v-for="(item, index) in headerHype"
                :key="index"
                @click="selectOne(index)"
                :class="index == indexOne ? 'selectClass' : ''"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="zBody">
              <div
                v-for="(item, index) in lableTitle"
                :key="index"
                @click="selectTo(index)"
                :class="index == indexTo ? 'selectClass' : ''"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="congtMain" style="margin-top: 60px">
          <div class="type">分组</div>
          <div class="mainRight">
            <div class="head">
              <div
                v-for="(item, index) in type"
                :key="index"
                @click="selectThrees(index)"
                :class="index == indexThree ? 'selectClass' : ''"
              >
                {{ item.name }}
              </div>
            </div>
            <!-- <div class="zBody">
              <div v-for="(item, index) in typeCont" :key="index">
                {{ item.name }}
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div v-if="!yucShow && !detailShow" class="footer">
        <div style="margin-right: 36px">已选</div>
        <div class="select" style="display: flex">
          <div>单期排行</div>
          <div v-if="indexOne != -1">
            排行：{{ headerHype[indexOne].name }}（{{
              indexTo == 0
                ? "百位"
                : indexTo == 1
                ? "十位"
                : indexTo == 2
                ? "个位"
                : indexTo == 3
                ? "和值"
                : indexTo == 4
                ? "跨度"
                : indexTo == 5
                ? "大小比"
                : indexTo == 6
                ? "奇偶比"
                : "质和比"
            }}）
          </div>
          <div v-if="indexThree != -1">
            分组：{{ type[indexThree].name }}（{{
              indexTo == 0
                ? "百位"
                : indexTo == 1
                ? "十位"
                : indexTo == 2
                ? "个位"
                : indexTo == 3
                ? "和值"
                : "跨度"
            }}{{
              indexThree == 0 && indexTo != 3 && indexTo != 4
                ? "定四码"
                : "杀四码"
            }}）
          </div>
        </div>
      </div>
      <lSha
        v-if="indexOne != -1 && indexThree != -1 && !yucShow"
        :selectIndex="indexOne"
        :indexTo="indexTo"
        :indexThree="indexThree"
        :getIssueData="getIssueData"
        :total="total"
        :tabelData="tabelData"
        @userDetail="userDetail"
        >= 0 && indexThree >= 0"></lSha
      >
      <pHang
        v-if="indexOne == -1 && indexThree == -1 && !yucShow"
        :total="total"
        :indexTo="indexTo"
        :tabelData="tabelData"
      ></pHang>
      <div class="dialog">
        <!-- :disabled="true" -->
        <el-dialog v-model="dialogVisible" title="" width="650px">
          <div v-if="!nextShow">
            <div class="headerDialog">请输入您的开奖号码：</div>
            <div style="display: flex; padding: 0 40px">
              <div class="number" style="margin-right: 20px">
                <div>期数</div>
                <el-input
                  style="flex: 1; margin-left: 20px"
                  v-model="qiNumber"
                  type="number"
                  placeholder="请输入期数"
                />
              </div>
              <div class="number">
                <div>中奖号码</div>
                <el-input
                  style="flex: 1; margin-left: 20px"
                  v-model="numberZj"
                  maxlength="3"
                  :disabled="nextShow"
                  @input="changeInt"
                  placeholder="请输入中奖号码"
                />
              </div>
            </div>
            <div class="dialogCont">
              <div
                class="int"
                v-for="(item, index) in label"
                :key="index"
                :style="(index + 1) % 3 == 0 ? 'margin-right:0px;' : ''"
              >
                <div class="titles">{{ item.name }}：</div>
                <el-input
                  maxlength="2"
                  v-model="item.value"
                  :disabled="nextShow"
                  placeholder="请输入"
                />
              </div>
            </div>
          </div>
          <!-- <div v-else>
            <div class="headerDialog">
              <el-icon
                @click="nextShow = false"
                color="#000000"
                size="24px"
                style="cursor: pointer"
                ><arrow-left
              /></el-icon>
              请确认您的开奖号码：
            </div>
            <div class="number" style="display: flex; padding: 0 40px">
              <div>期数</div>
              <el-input
                style="flex: 1; margin-left: 20px"
                v-model="qiNumber"
                :disabled="nextShow"
                placeholder="请输入期数"
              />
            </div>
            <div class="dialogCont">
              <div
                class="int"
                v-for="(item, index) in label"
                :key="index"
                :style="(index + 1) % 3 == 0 ? 'margin-right:0px;' : ''"
              >
                <div class="title">{{ item.nameTo }}：</div>
                <el-input
                  v-model="item.value"
                  maxlength="1"
                  :disabled="nextShow"
                  placeholder="请输入"
                />
              </div>
            </div>
          </div> -->
          <div
            style="
              display: flex;
              align-items: flex-end;
              flex-direction: column;
              padding-right: 20px;
              margin-top: 0px;
            "
          >
            <el-button
              type="danger"
              style="margin-right: 60px"
              size="large"
              @click="nextButTO"
              >确定</el-button
            >
            <div>**确认后系统将为您进行提取数据</div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import lSha from "./liansha.vue";
import pHang from "./paihang.vue";
import yuCe from "./yuce.vue";
import userDetail from "./userDetail.vue";
import api from "./../../zRequest/api.js";
import { ElMessage, ElLoading, ElMessageBox } from "element-plus";
import { ref } from "vue";
var loading;
export default {
  data() {
    return {
      detailShow: false,
      detailData: [],
      numberZj: "",
      nextShow: false,
      dialogVisible: false,
      input: "",
      qiNumber: "", //期号
      label: [
        {
          name: "请输入百位开奖号",
          nameTo: "百位开奖号",
          value: "",
        },
        {
          name: "请输入十位开奖号",
          nameTo: "十位开奖号",
          value: "",
        },
        {
          name: "请输入个位开奖号",
          nameTo: "个位开奖号",
          value: "",
        },
        {
          name: "请输入和值开奖号",
          nameTo: "和值开奖号",
          value: "",
        },
        {
          name: "请输入跨度开奖号",
          nameTo: "跨度开奖号",
          value: "",
        },
        {
          name: "请输入大小比开奖号",
          nameTo: "质和开奖号",
          value: "",
        },
        {
          name: "请输入奇偶比比开奖号",
          nameTo: "质和开奖号",
          value: "",
        },
        {
          name: "请输入质和比开奖号",
          nameTo: "质和开奖号",
          value: "",
        },
      ],
      getIssueData: "",
      headerHype: [
        {
          name: "连对榜",
        },
        {
          name: "连错榜",
        },
      ],
      lableTitle: [
        {
          name: "百位",
        },
        {
          name: "十位",
        },
        {
          name: "个位",
        },
        {
          name: "和值",
        },
        {
          name: "跨度",
        },
        // {
        //   name: "质和",
        // },
        {
          name: "大小比",
        },
        {
          name: "奇偶比",
        },
        {
          name: "质和比",
        },
      ],
      type: [
        {
          name: "定胆",
        },
        {
          name: "杀号",
        },
      ],
      typeCont: [
        {
          name: "百位定四码",
        },
        {
          name: "百位杀四码",
        },
      ],
      indexOne: -1,
      indexTo: 0,
      indexThree: -1,
      tabelData: "",
      total: 0,
      requestBlooen: false,
      yucShow: false,
      yuceData: [],
      totalQiData: [],
    };
  },
  components: { lSha, pHang, yuCe, userDetail },
  created() {
    this.requestBlooen = false;
    this.getIssue();
    this.totalQi();
    this.total_nums();
    api.getIssue_yuce().then((res) => {
      this.qiNumber = Number(res.data.issue);
      this.qiNumberYu = Number(res.data.issue);
    });
  },
  methods: {
    delData() {
      let that = this;
      ElMessageBox.confirm("是否清空数据,清除后不可恢复?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.delData().then((res) => {
            ElMessage({
              type: "success",
              message: "删除成功",
            });
            this.requestBlooen = false;
            this.getIssue();
            this.totalQi();
            this.total_nums();
            api.getIssue_yuce().then((res) => {
              this.qiNumber = Number(res.data.issue);
              this.qiNumberYu = Number(res.data.issue);
            });
          });
        })
        .catch(() => {});
    },
    total_nums() {
      api.total_nums().then((res) => {
        this.total = res.total;
      });
    },
    backDetail() {
      this.detailShow = false;
    },
    userDetail(id) {
      api.userDetail_pay({ id: id }).then((res) => {
        this.detailData = res.data;
        this.detailShow = true;
      });
    },
    totalQi() {
      api.totalQi().then((res) => {
        this.totalQiData = res.data;
      });
    },
    back() {
      this.yucShow = false;
    },
    yuce() {
      // this.yuceFun(2)
      // return;

      api.yuceNum({ issue: this.qiNumberYu + "" }).then((res) => {
        this.yuceData = res.data;
      });
      this.yucShow = true;
    },
    yuceChange(num) {
      api.yuceNum({ issue: num }).then((res) => {
        this.yuceData = res.data;
      });
    },
    selectOne(index) {
      let that = this;
      this.indexOne = index;
      this.indexThree = this.indexThree == -1 ? 0 : this.indexThree;
      this.selectFun();
    },

    selectTo(index) {
      let that = this;
      this.indexTo = index;
      this.indexOne = -1;
      this.indexThree = -1;
      this.selectFun();
    },
    selectThrees(index) {
      this.indexOne = this.indexOne == -1 ? 0 : this.indexOne;
      this.indexThree = index;
      this.selectFun();
    },
    selectFun() {
      if (this.indexTo == 0) {
        this.getPh_bai(this.indexOne, this.indexThree, this.indexTo);
      } else if (this.indexTo == 1) {
        this.getPh_shi(this.indexOne, this.indexThree, this.indexTo);
      } else if (this.indexTo == 2) {
        this.getPh_ge(this.indexOne, this.indexThree, this.indexTo);
      } else if (this.indexTo == 3) {
        this.getPh_hezhi(this.indexOne, this.indexThree, this.indexTo);
      } else if (this.indexTo == 4) {
        this.getPh_kuadu(this.indexOne, this.indexThree, this.indexTo);
      } else if (this.indexTo == 5) {
        this.getPh_daxiao(this.indexOne, this.indexThree, this.indexTo);
      } else if (this.indexTo == 6) {
        this.getPh_jiou(this.indexOne, this.indexThree, this.indexTo);
      } else if (this.indexTo == 7) {
        this.getPh_zhihe(this.indexOne, this.indexThree, this.indexTo);
      }
    },
    getPh_bai(type, b_type, a_type) {
      let data = {
        type: type,
        a_type: a_type,
        b_type: b_type,
      };
      api.getPh_bai(data).then((res) => {
        this.tabelData = res.data;
      });
    },
    getPh_shi(type, b_type, a_type) {
      let data = {
        type: type,
        a_type: a_type,
        b_type: b_type,
      };
      console.log("的萨达是");
      api.getPh_shi(data).then((res) => {
        let data = res.data;
        console.log(data);
        this.tabelData = res.data;
      });
    },
    getPh_ge(type, b_type, a_type) {
      let data = {
        type: type,
        a_type: a_type,
        b_type: b_type,
      };
      api.getPh_ge(data).then((res) => {
        this.tabelData = res.data;
      });
    },
    getPh_hezhi(type, b_type, a_type) {
      let data = {
        type: type,
        a_type: a_type,
        b_type: b_type,
      };
      api.getPh_hezhi(data).then((res) => {
        this.tabelData = res.data;
      });
    },
    getPh_kuadu(type, b_type, a_type) {
      let data = {
        type: type,
        a_type: a_type,
        b_type: b_type,
      };
      api.getPh_kuadu(data).then((res) => {
        this.tabelData = res.data;
      });
    },
    getPh_daxiao(type, b_type, a_type) {
      let data = {
        type: type,
        a_type: a_type,
        b_type: b_type,
      };
      api.getPh_daxiao(data).then((res) => {
        this.tabelData = res.data;
      });
    },
    getPh_jiou(type, b_type, a_type) {
      let data = {
        type: type,
        a_type: a_type,
        b_type: b_type,
      };
      api.getPh_jiou(data).then((res) => {
        this.tabelData = res.data;
      });
    },
    getPh_zhihe(type, b_type, a_type) {
      let data = {
        type: type,
        a_type: a_type,
        b_type: b_type,
      };
      api.getPh_zhihe(data).then((res) => {
        this.tabelData = res.data;
      });
    },

    // 预测数据
    yuceFun() {
      this.select(2);
    },
    addBai_yuce() {
      let arry = [];
      let e = 0;
      for (let i = 0; i < this.userArry_yuce.length; i++) {
        api
          .addBai_yuce({
            item: this.userArry_yuce[i],
          })
          .then((res) => {
            e++;
            if (e >= this.userArry_yuce.length) {
              loading.close();
            }
          });
      }
    },
    // 最新期数-数据
    getIssue() {
      api.getIssue().then((res) => {
        if (res.code == 1) {
          this.getIssueData = res.data ? res.data : "";
          if (res.data) {
            //  this.getPh_bai(this.indexOne, this.indexThree, this.indexTo);
            this.selectFun();
            if (this.requestBlooen) {
              // this.select();
              if (this.total <= 0) {
                this.select();
              } else {
                this.yuceUp();
              }
            }
          }
        }
      });
    },
    select(type) {
      let that = this;
      api.select().then((res) => {
        if (res.code == 1) {
          if (type == 2) {
            this.userArry_yuce = res.data;
            this.userArry_yuce.forEach((item) => {
              item.issue = Number(this.getIssueData.issue) + 1;
              let returnNumber1 = that.returnNumber(); //百位
              let returnNumber2 = that.returnNumber(); //十位
              let returnNumber3 = that.returnNumber(); //个位
              let returnNumber4 = that.returnNumber(6); //和值
              let returnNumber5 = that.returnNumber(); //跨度
              let returnNumber6 = that.returnNumber(4); //大小比
              let returnNumber7 = that.returnNumber(4); //奇偶比
              let returnNumber8 = that.returnNumber(4); //质和比

              // console.log(returnNumber4);
              // returnNumber4
              // returnNumber4 =returnNumber4.sort(function (a, b) {
              //   return a - b;
              // });
              // console.log(returnNumber4,'排序');
              /*  --------------百位-------------------- */
              item.pay_num_bai_ding = returnNumber1[0].join(",");
              item.pay_num_bai_sha = returnNumber1[1].join(",");
              item.is_zhong_bai_ding = 0;
              item.is_zhong_bai_sha = 0;
              /* -----------------end---------------------- */
              /*  --------------十位-------------------- */
              item.pay_num_shi_ding = returnNumber2[0].join(",");
              item.pay_num_shi_sha = returnNumber2[1].join(",");
              item.is_zhong_shi_ding = 0;
              item.is_zhong_shi_sha = 0;
              /* -----------------end---------------------- */
              /*  --------------个位-------------------- */
              item.pay_num_ge_ding = returnNumber3[0].join(",");
              item.pay_num_ge_sha = returnNumber3[1].join(",");
              item.is_zhong_ge_ding = 0;
              item.is_zhong_ge_sha = 0;
              /* -----------------end---------------------- */
              /*  --------------和值-------------------- */
              item.pay_num_hezhi_ding = returnNumber4[0].join(",");
              item.pay_num_hezhi_sha = returnNumber4[1].join(",");
              item.is_zhong_hezhi_ding = 0;
              item.is_zhong_hezhi_sha = 0;
              /* -----------------end---------------------- */
              /*  --------------跨度-------------------- */
              item.pay_num_kuadu_ding = returnNumber5[0].join(",");
              item.pay_num_kuadu_sha = returnNumber5[1].join(",");
              item.is_zhong_kuadu_ding = 0;
              item.is_zhong_kuadu_sha = 0;
              /* -----------------end---------------------- */

              /*  --------------大小比-------------------- */
              item.pay_num_daxiao_ding = returnNumber6[0].join(",");
              item.pay_num_daxiao_sha = returnNumber6[1].join(",");
              item.is_zhong_daxiao_ding = 0;
              item.is_zhong_daxiao_sha = 0;
              /* -----------------end---------------------- */

              /*  --------------奇偶比-------------------- */
              item.pay_num_jiou_ding = returnNumber7[0].join(",");
              item.pay_num_jiou_sha = returnNumber7[1].join(",");
              item.is_zhong_jiou_ding = 0;
              item.is_zhong_jiou_sha = 0;
              /* -----------------end---------------------- */

              /*  --------------质和比-------------------- */
              item.pay_num_zhihe_ding = returnNumber8[0].join(",");
              item.pay_num_zhihe_sha = returnNumber8[1].join(",");
              item.is_zhong_zhihe_ding = 0;
              item.is_zhong_zhihe_sha = 0;
              /* -----------------end---------------------- */
            });

            this.addBai_yuce();
            return false;
          }
          this.userArry = res.data;
          this.userArry.forEach((item) => {
            item.issue = this.getIssueData.issue;
            let returnNumber1 = that.returnNumber(); //百位
            let returnNumber2 = that.returnNumber(); //十位
            let returnNumber3 = that.returnNumber(); //个位
            let returnNumber4 = that.returnNumber(6); //和值
            let returnNumber5 = that.returnNumber(); //跨度
            let returnNumber6 = that.returnNumber(4); //大小比
            let returnNumber7 = that.returnNumber(4); //奇偶比
            let returnNumber8 = that.returnNumber(4); //质和比

            /*  --------------百位-------------------- */
            item.pay_num_bai_ding = returnNumber1[0].join(",");
            item.pay_num_bai_sha = returnNumber1[1].join(",");
            item.is_zhong_bai_ding =
              returnNumber1[0].find(
                (obj) => obj == this.getIssueData.bai_num
              ) != undefined
                ? 1
                : 0;
            item.is_zhong_bai_sha =
              returnNumber1[1].find(
                (obj) => obj == this.getIssueData.bai_num
              ) != undefined
                ? 0
                : 1;
            /* -----------------end---------------------- */
            /*  --------------十位-------------------- */
            item.pay_num_shi_ding = returnNumber2[0].join(",");
            item.pay_num_shi_sha = returnNumber2[1].join(",");
            item.is_zhong_shi_ding =
              returnNumber2[0].find(
                (obj) => obj == this.getIssueData.shi_num
              ) != undefined
                ? 1
                : 0;
            item.is_zhong_shi_sha =
              returnNumber2[1].find(
                (obj) => obj == this.getIssueData.shi_num
              ) != undefined
                ? 0
                : 1;
            /* -----------------end---------------------- */
            /*  --------------个位-------------------- */
            item.pay_num_ge_ding = returnNumber3[0].join(",");
            item.pay_num_ge_sha = returnNumber3[1].join(",");
            item.is_zhong_ge_ding =
              returnNumber3[0].find((obj) => obj == this.getIssueData.ge_num) !=
              undefined
                ? 1
                : 0;
            item.is_zhong_ge_sha =
              returnNumber3[1].find((obj) => obj == this.getIssueData.ge_num) !=
              undefined
                ? 0
                : 1;
            /* -----------------end---------------------- */
            /*  --------------和值-------------------- */
            item.pay_num_hezhi_ding = returnNumber4[0].join(",");
            item.pay_num_hezhi_sha = returnNumber4[1].join(",");
            item.is_zhong_hezhi_ding =
              returnNumber4[0].find((obj) => obj == this.getIssueData.he_num) !=
              undefined
                ? 1
                : 0;
            item.is_zhong_hezhi_sha =
              returnNumber4[1].find((obj) => obj == this.getIssueData.he_num) !=
              undefined
                ? 0
                : 1;

            if (item.is_zhong_hezhi_ding == 1) {
              console.log("和值白定", item);
            }
            /* -----------------end---------------------- */
            /*  --------------跨度-------------------- */
            item.pay_num_kuadu_ding = returnNumber5[0].join(",");
            item.pay_num_kuadu_sha = returnNumber5[1].join(",");
            item.is_zhong_kuadu_ding =
              returnNumber5[0].find(
                (obj) => obj == this.getIssueData.kua_num
              ) != undefined
                ? 1
                : 0;
            item.is_zhong_kuadu_sha =
              returnNumber5[1].find(
                (obj) => obj == this.getIssueData.kua_num
              ) != undefined
                ? 0
                : 1;
            /* -----------------end---------------------- */

            /*  --------------大小比-------------------- */
            item.pay_num_daxiao_ding = returnNumber6[0].join(",");
            item.pay_num_daxiao_sha = returnNumber6[1].join(",");
            item.is_zhong_daxiao_ding =
              returnNumber6[0].find(
                (obj) => obj == this.getIssueData.daxiao_num
              ) != undefined
                ? 1
                : 0;
            item.is_zhong_daxiao_sha =
              returnNumber6[1].find(
                (obj) => obj == this.getIssueData.daxiao_num
              ) != undefined
                ? 0
                : 1;
            /* -----------------end---------------------- */

            /*  --------------奇偶比-------------------- */
            item.pay_num_jiou_ding = returnNumber7[0].join(",");
            item.pay_num_jiou_sha = returnNumber7[1].join(",");
            item.is_zhong_jiou_ding =
              returnNumber7[0].find(
                (obj) => obj == this.getIssueData.jiou_num
              ) != undefined
                ? 1
                : 0;
            item.is_zhong_jiou_sha =
              returnNumber7[1].find(
                (obj) => obj == this.getIssueData.jiou_num
              ) != undefined
                ? 0
                : 1;
            /* -----------------end---------------------- */

            /*  --------------质和比-------------------- */
            item.pay_num_zhihe_ding = returnNumber8[0].join(",");
            item.pay_num_zhihe_sha = returnNumber8[1].join(",");
            item.is_zhong_zhihe_ding =
              returnNumber8[0].find(
                (obj) => obj == this.getIssueData.zhihe_num
              ) != undefined
                ? 1
                : 0;
            item.is_zhong_zhihe_sha =
              returnNumber8[1].find(
                (obj) => obj == this.getIssueData.zhihe_num
              ) != undefined
                ? 0
                : 1;
            /* -----------------end---------------------- */
          });

          if (this.requestBlooen) {
            this.addBai();
          }
        }
      });
    },
    returnNumber(type) {
      let item;
      let arrLen;
      if (type == 6) {
        arrLen = 6;
        item = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
      } else if (type == 3) {
        arrLen = 3;
        item = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      } else if (type == 4) {
        arrLen = 3;
        item = [1, 2, 3, 4, 5, 6, 7, 8];
      } else {
        arrLen = 4;
        item = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      }
      item = item.sort(this.randomsort);
      var newDataArr = [];
      for (var i = 0; i < item.length; i += arrLen) {
        let is = item.slice(i, i + arrLen);
        is.sort(function (a, b) {
          return a - b;
        });
        newDataArr.push(is);
      }

      return newDataArr;
    },
    // 随机打乱数组
    randomsort(a, b) {
      return Math.random() > 0.5 ? -1 : 1;
    },

    // 用户购买中奖号码--百位
    addBai() {
      let arry = [];
      let e = 0;
      for (let i = 0; i < this.userArry.length; i++) {
        api
          .addBai({
            item: this.userArry[i],
          })
          .then((res) => {
            e++;
            if (e >= this.userArry.length) {
              if (this.requestBlooen) {
                this.addBai_liandui();
                this.add_Shi_liandui();
                this.add_ge_liandui();
                this.add_hezhi_liandui();
                this.add_kuadu_liandui();
                this.add_daxiao_liandui();
                this.add_jiou_liandui();
                this.add_zhihe_liandui();
              }
            }
          });
      }
    },
    async addBai_liandui() {
      await api.addBai_liandui().then((res) => {});
    },
    async update_fenshu() {
      api.update_fenshu().then((res) => {
        this.requestBlooen = false;
        this.getIssue();
        this.totalQi();
        this.total_nums();
      });
    },
    async add_Shi_liandui() {
      await api.add_Shi_liandui().then((res) => {});
    },
    async add_ge_liandui() {
      await api.add_ge_liandui().then((res) => {});
    },
    async add_hezhi_liandui() {
      await api.add_hezhi_liandui().then((res) => {});
    },
    async add_kuadu_liandui() {
      await api.add_kuadu_liandui().then((res) => {});
    },
    async add_daxiao_liandui() {
      await api.add_daxiao_liandui().then((res) => {});
    },
    async add_jiou_liandui() {
      await api.add_jiou_liandui().then((res) => {});
    },
    async add_zhihe_liandui() {
      await api.add_zhihe_liandui().then((res) => {
        this.update_fenshu();

        this.yuceFun(2);
      });
    },

    changeInt() {
      this.numberZj = this.numberZj.replace(/[^\d]/g, "");
      let num = this.numberZj.split("").map(Number);

      // 百位 十 个
      [this.label[0].value, this.label[1].value, this.label[2].value] = [
        ...num,
      ];
      // 和值
      this.label[3].value = this.numberZj ? num.reduce((n, m) => n + m) : "";
      // 跨度
      this.label[4].value = this.numberZj
        ? Math.max(...num) - Math.min(...num)
        : "";
    },
    entryNumber() {
      api.getIssue_yuce().then((res) => {
        if (res.data) {
          this.qiNumber = Number(res.data.issue);
          this.qiNumberYu = Number(res.data.issue);
          this.dialogVisible = true;
        } else {
          this.qiNumber = "";
          this.qiNumberYu = "";
          this.dialogVisible = true;
        }
      });
    },
    // 生成用户
    generate() {
      api.addUserTo().then((res) => {});
    },
    // 录入中奖号码
    nextButTO() {
      api.selectIssue({ issue: this.qiNumber }).then((res) => {
        console.log(res);
        if (res.count >= 1) {
          ElMessage("期数不可重复");
        } else {
          this.nextBut();
        }
      });
    },
    nextBut() {
      let that = this;
      let blo = false;

      if (!this.numberZj) {
        ElMessage("请填写中奖号码");
        return false;
      }
      if (!this.label[5].value) {
        ElMessage("请填写大小比中奖号");
        return false;
      }
      if (!this.label[5].value) {
        ElMessage("请填写大小比中奖号");
        return false;
      }
      if (!this.label[6].value) {
        ElMessage("请填写奇偶比中奖号");
        return false;
      }
      if (!this.label[7].value) {
        ElMessage("请填写质和比中奖号");
        return false;
      }
      let data = {
        issue: this.qiNumber,
        number: this.numberZj,
        bai_num: this.label[0].value,
        shi_num: this.label[1].value,
        ge_num: this.label[2].value,
        he_num: this.label[3].value,
        kua_num: this.label[4].value,
        daxiao_num: this.label[5].value,
        jiou_num: this.label[6].value,
        zhihe_num: this.label[7].value,
      };
      api.addKaijiang(data).then((res) => {
        if (res.code == 1) {
          ElMessage(res.msg);
          loading = ElLoading.service({
            lock: true,
            text: "正在提交数据中...",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that.dialogVisible = false;
          that.requestBlooen = true;
          that.getIssue();
        }
      });
      /*  if (!this.nextShow) {
        this.nextShow = true;
      } else {
        let data = {
          issue: this.qiNumber,
          number: this.numberZj,
          bai_num: this.label[0].value,
          shi_num: this.label[1].value,
          ge_num: this.label[2].value,
          he_num: this.label[3].value,
          kua_num: this.label[4].value,
          daxiao_num: this.label[5].value,
          jiou_num: this.label[6].value,
          zhihe_num: this.label[7].value,
        };
        console.log(data);
        api.addKaijiang(data).then((res) => {
          if (res.code == 1) {
            ElMessage(res.msg);
            that.dialogVisible = false;
            that.requestBlooen = true;
            that.getIssue();
          }
        });
      } */
    },
    // 更新预测数据
    yuceUp() {
      api.yuceNum({ issue: this.qiNumberYu + "" }).then((res) => {
        // this.yuceData = res.data;
        let e = 0;
        let data = res.data;
        this.update_issue();
        data.forEach((item) => {
          item.issue = this.qiNumber;
          item.name = item.user_name;

          item.id = item.user_id;
          /*  --------------百位-------------------- */
          item.is_zhong_bai_ding =
            item.pay_num_bai_ding
              .split(",")
              .find((obj) => obj == this.getIssueData.bai_num) != undefined
              ? 1
              : 0;
          item.is_zhong_bai_sha =
            item.pay_num_bai_sha
              .split(",")
              .find((obj) => obj == this.getIssueData.bai_num) != undefined
              ? 0
              : 1;
          /* -----------------end---------------------- */
          /*  --------------十位-------------------- */
          item.is_zhong_shi_ding =
            item.pay_num_shi_ding
              .split(",")
              .find((obj) => obj == this.getIssueData.shi_num) != undefined
              ? 1
              : 0;
          item.is_zhong_shi_sha =
            item.pay_num_shi_sha
              .split(",")
              .find((obj) => obj == this.getIssueData.shi_num) != undefined
              ? 0
              : 1;
          /* -----------------end---------------------- */
          /*  --------------个位-------------------- */
          item.is_zhong_ge_ding =
            item.pay_num_ge_ding
              .split(",")
              .find((obj) => obj == this.getIssueData.ge_num) != undefined
              ? 1
              : 0;
          item.is_zhong_ge_sha =
            item.pay_num_ge_sha
              .split(",")
              .find((obj) => obj == this.getIssueData.ge_num) != undefined
              ? 0
              : 1;
          /* -----------------end---------------------- */
          /*  --------------和值-------------------- */
          item.is_zhong_hezhi_ding =
            item.pay_num_hezhi_ding
              .split(",")
              .find((obj) => obj == this.getIssueData.he_num) != undefined
              ? 1
              : 0;
          item.is_zhong_hezhi_sha =
            item.pay_num_hezhi_sha
              .split(",")
              .find((obj) => obj == this.getIssueData.he_num) != undefined
              ? 0
              : 1;
          /* -----------------end---------------------- */
          /*  --------------跨度-------------------- */
          item.is_zhong_kuadu_ding =
            item.pay_num_kuadu_ding
              .split(",")
              .find((obj) => obj == this.getIssueData.kua_num) != undefined
              ? 1
              : 0;
          item.is_zhong_kuadu_sha =
            item.pay_num_kuadu_sha
              .split(",")
              .find((obj) => obj == this.getIssueData.kua_num) != undefined
              ? 0
              : 1;
          /* -----------------end---------------------- */

          /*  --------------大小比-------------------- */
          item.is_zhong_daxiao_ding =
            item.pay_num_daxiao_ding
              .split(",")
              .find((obj) => obj == this.getIssueData.daxiao_num) != undefined
              ? 1
              : 0;
          item.is_zhong_daxiao_sha =
            item.pay_num_daxiao_sha
              .split(",")
              .find((obj) => obj == this.getIssueData.daxiao_num) != undefined
              ? 0
              : 1;
          /* -----------------end---------------------- */

          /*  --------------奇偶比-------------------- */
          item.is_zhong_jiou_ding =
            item.pay_num_jiou_ding
              .split(",")
              .find((obj) => obj == this.getIssueData.jiou_num) != undefined
              ? 1
              : 0;
          item.is_zhong_jiou_sha =
            item.pay_num_jiou_sha
              .split(",")
              .find((obj) => obj == this.getIssueData.jiou_num) != undefined
              ? 0
              : 1;
          /* -----------------end---------------------- */

          /*  --------------质和比-------------------- */
          item.is_zhong_zhihe_ding =
            item.pay_num_zhihe_ding
              .split(",")
              .find((obj) => obj == this.getIssueData.zhihe_num) != undefined
              ? 1
              : 0;
          item.is_zhong_zhihe_sha =
            item.pay_num_zhihe_sha
              .split(",")
              .find((obj) => obj == this.getIssueData.zhihe_num) != undefined
              ? 0
              : 1;
          /* -----------------end---------------------- */
          // this.yuceNum_upDate(item)
          api
            .addBai({
              item: item,
            })
            .then((res) => {
              e++;
              if (e >= data.length) {
                if (this.requestBlooen) {
                  this.addBai_liandui();
                  this.add_Shi_liandui();
                  this.add_ge_liandui();
                  this.add_hezhi_liandui();
                  this.add_kuadu_liandui();
                  this.add_daxiao_liandui();
                  this.add_jiou_liandui();
                  this.add_zhihe_liandui();
                }
              }
            });
        });
      });
    },
    update_issue() {
      api
        .update_issue({
          issue: this.qiNumber,
          issue_old: this.qiNumberYu,
        })
        .then((res) => {});
    },
    yuceNum_upDate(item) {
      let data = {
        id: item.id,
        is_zhong_bai_ding: item.is_zhong_bai_ding,
        is_zhong_bai_sha: item.is_zhong_bai_sha,
        is_zhong_shi_ding: item.is_zhong_shi_ding,
        is_zhong_shi_sha: item.is_zhong_shi_sha,
      };
      api.yuceNum_upDate(data).then((res) => {});
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
.selectClass {
  background: red !important;
  color: #ffffff !important;
  border: 1px solid red !important;
}
.dialog {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0 0 40px 0;
  }
  .headerDialog {
    padding: 20px 48px;
    background: #edf1f2;
    color: #ff2525;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #bbbbbb;
  }
  .number {
    padding: 0;
    margin: 20px 0;
    display: flex;
    align-items: center;
    width: 300px;
  }

  .dialogCont {
    display: flex;
    flex-wrap: wrap;
    padding: 0 40px;
    .int {
      width: 170px;
      height: 85px;
      border: 1px solid #bbbbbb;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 20px 20px 0;
      .el-input {
        width: 80%;
        margin-top: 10px;
        input {
          border: none;
        }
      }
    }
  }
}
.index {
  background: #f7f7f7f7;
  .mains {
    width: 1400px;
    margin: 0 auto;
    background: #ffffff;
    // height: 100vh;
    .header {
      display: flex;
      padding: 20px;
      background: #e1e3e6;
      .name,
      .title {
        font-size: 16px;
        margin-right: 30px;
        color: #101010;
        padding: 10px 0 0 0;
      }
      .num {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px 0 0 0;
        button {
          height: 25px !important;
          padding: 0px 10px !important;
          margin-top: 5px;
        }
      }

      .rightCont {
        display: flex;
        flex: 1;

        .numCont {
          display: flex;
          flex: 1;
          justify-content: space-around;
          align-items: center;
          div {
            font-size: 14px;
            text-align: center;
            background: red;
            border-radius: 5px;
            padding: 5px;
            color: #ffffff;
          }
        }
        .pai {
          background: red;
          color: #ffffff;
          border-radius: 5px;
          padding: 5px;
          width: 79px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          margin-top: 5px;
          cursor: pointer;
        }
      }
    }
    .cont {
      padding: 20px 30px;
      background: #ffffff;
      .congtMain {
        display: flex;

        .type {
          width: 70px;
          line-height: 45px;
          height: 45px;
          text-align: center;
          font-weight: 600;
          border: 1px solid #bbbbbb;
          margin-right: 92px;
        }
        .mainRight {
          flex: 1;
          .head {
            display: flex;
            div {
              width: 128px;
              padding: 14px 0px;
              border: 1px solid #bbbbbb;
              border-radius: 5px;
              text-align: center;
              background: #edf1f2;
              font-size: 16px;
              margin-right: 50px;
              cursor: pointer;
            }
          }
          .zBody {
            margin-top: 24px;
            display: flex;
            div {
              padding: 10px 20px;
              border: 1px solid #bbbbbb;
              border-radius: 5px;
              margin-right: 30px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .footer {
      padding: 0 30px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #101010;
      background: #e1e3e6;
      height: 60px;
      .select {
        div {
          margin-right: 20px;
          border: 1px solid #bbbbbb;
          border-radius: 5px;
          background: #ffffff;
          padding: 2px 5px;
        }
      }
    }
  }
}
</style>
